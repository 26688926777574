import { Resource } from '@rest-hooks/rest';

class NetworkErrorExt extends Error {
  name = 'NetworkError';

  constructor(response, data) {
    super(response.statusText || `Network response not 'ok': ${response.status}`);
    this.status = response.status;
    this.response = response;
    this.response.data = data;
  }
}

async function getResponseBody(response) {
  try {
    if (!response.headers.get('content-type')?.includes('json') || response.status === 204) return await response.text();
    return await response.json();
  } catch (_e) {
    return null;
  }
}

export default class RailsResource extends Resource {
  static getFetchInit = (init) => {
    const token = document.querySelector('[name=csrf-token]')?.content || 'test';
    return {
      ...init,
      headers: {
        ...init.headers,
        Accept: 'application/json',
        'X-CSRF-TOKEN': token,
      },
    };
  };

  /** Catch exception and extend it with response body */
  static fetch(input, init) {
    return super.fetch(input, init).catch((error) => {
      if (!error?.response?.ok) {
        return getResponseBody(error.response).then((data) => {
          throw new NetworkErrorExt(error.response, data);
        });
      }
      throw error;
    });
  }
}
