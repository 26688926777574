import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';

export default (props) => {
  const style = {
    cursor: 'pointer',
    padding: '0 5px',
    fontSize: '20px',
    color: 'rgb(112, 112, 112)',
  };

  return (
    <a onClick={props.handleClick} className="edit-btn no-print" data-cy="edit">
      <FontAwesomeIcon icon={faPen} style={style} />
    </a>
  );
};
