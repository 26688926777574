/**
 * Show delegation fields tooltip if the element is delegated, or if the element is created by delegation.
 * @param props
 * @returns {boolean}
 */
export const showDelegationFieldsToolTip = (props) => {
  return props['delegation_sync_fields'].length > 0 && (props['delegated?'] === true || props['delegated_from_org_name'] !== null);
};

export const isFieldSynced = (field, elementProps) => {
  if (!elementProps) return false;
  const isDelegatedFromParent = elementProps['delegated_from_org_name'];
  const syncFields = elementProps['delegation_sync_fields'];
  return isDelegatedFromParent && syncFields.includes(field);
};
