import * as React from 'react';
import TextField from '@mui/material/TextField';
import DeleteAction from '../actions/delete';
import UploadAction from '../actions/upload';
import Resources from '../shared/resources';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { isFieldSynced } from '../../helpers/delegation_helper';

// eslint-disable-next-line
export default ({ id, ...props }) => {
  const handleDelete = () => {
    const confirmDelete = confirm('Are you sure you want to delete this result?');
    if (confirmDelete) {
      props.deleteResult(props.result);
    }
  };

  const handleUpload = (result) => {
    props.updateResult(result);
    props.updateResultAttachments(result);
  };

  const removeAttachment = (newAttachments) => {
    props.updateResult({ ...props.result, attachment_attributes: newAttachments });
    props.updateResultAttachments(newAttachments);
  };

  return (
    <TableRow>
      <TableCell className="border-0">
        <TextField
          className="me-3 w-100"
          id="result-date"
          label="Due Date"
          variant="outlined"
          value={props.result.due_date}
          type="date"
          error={props.errors.due_date && props.errors.due_date.join(', ')}
          helperText={props.errors.due_date}
          onChange={(e) => {
            const value = e.currentTarget.value;
            props.updateResult({ ...props.result, due_date: value });
          }}
          InputLabelProps={{ shrink: true }}
          disabled={isFieldSynced('due_date', props.result)}
        />
      </TableCell>
      <TableCell className="border-0">
        <TextField
          className="me-3 w-100"
          id="result-actual-result"
          label="Actual Result"
          variant="outlined"
          value={props.result.actual_results}
          error={props.errors.actual_results && props.errors.actual_results.join(', ')}
          helperText={props.errors.actual_results}
          onChange={(e) => {
            const value = e.currentTarget.value;
            props.updateResult({ ...props.result, actual_results: value });
          }}
        />
      </TableCell>
      <TableCell className="border-0">
        <TextField
          multiline
          className="me-3 w-100"
          id="result-notes"
          label="Notes"
          variant="outlined"
          value={props.result.notes || ''}
          onChange={(e) => {
            const value = e.currentTarget.value;
            props.updateResult({ ...props.result, notes: value });
          }}
          disabled={isFieldSynced('notes', props.result)}
        />
      </TableCell>
      <TableCell className="border-0">
        <div className="d-flex h-100 align-items-center">
          <div className="w-100 d-flex justify-content-center">
            <div className="d-flex justify-content-center">
              <Resources resources={props.result.attachment_attributes} handleUpdate={removeAttachment} />
              {props.result.id && (
                <div className="me-2 d-flex align-items-center">
                  <UploadAction url={`/results/${props.result.id}/attach_file`} handleUpload={handleUpload} className="ms-2"></UploadAction>
                </div>
              )}
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell className="border-0">
        <DeleteAction handleClick={handleDelete} />
      </TableCell>
    </TableRow>
  );
};
