import RailsResource from './rails_resource';
import MeasureResource from './measure_resource';

export default class ResultResource extends RailsResource {
  id = undefined;
  due_date = null;
  actual_results = null;
  attachment_attributes = [];
  notes = undefined;
  index = undefined;
  lock_version = null;

  pk() {
    return this.id;
  }

  static urlRoot = '/results/';
}

MeasureResource.schema = {
  results: [ResultResource],
};
