export const PLAN_ELEMENT_TYPES = {
  strategic_priority: 'StrategicPriority',
  initiative: 'Initiative',
  deliverable: 'Deliverable',
  measure: 'Measure',
};

/**
 *
 * @param {Object[]} projectPlans
 * @param {Object} projectPlans[].org
 * @param {Number} projectPlans[].org.id
 * @param {Number} projectPlans[].org.parent_id
 * @param {String} projectPlans[].org.name
 * @returns {*[]}
 */
export const buildTreeFromProjectPlans = (projectPlans) => {
  let temp = {};
  let tree = [];

  // prepare the data lookup
  projectPlans.forEach((plan) => {
    temp[plan.org.id] = { ...plan, children: [] };
  });

  projectPlans.forEach((item) => {
    if (item.org.parent_id && temp[item.org.parent_id]) {
      temp[item.org.parent_id].children.push(temp[item.org.id]);
    } else {
      tree.push(temp[item.org.id]);
    }
  });
  return tree;
};

export const buildTreeFromOrganizations = (organizations) => {
  let temp = {};
  let tree = [];

  if (!organizations) {
    return tree;
  }

  // prepare the data lookup
  organizations.forEach((org) => {
    temp[org.id] = { ...org, children: [] };
  });

  organizations.forEach((item) => {
    if (item.parent_id && temp[item.parent_id]) {
      temp[item.parent_id].children.push(temp[item.id]);
    } else {
      tree.push(temp[item.id]);
    }
  });
  return tree;
};

export const selectNone = (prevState) => {
  const newState = new Map();
  prevState.forEach((_, key) => {
    newState.set(key, false);
  });
  return newState;
};

export const selectAll = (prevState) => {
  const newState = new Map();
  prevState.forEach((_, key) => {
    newState.set(key, true);
  });
  return newState;
};

export const updateSingleCheck = (prevState, id, isChecked) => {
  const newState = new Map(prevState);
  newState.set(id, isChecked);
  return newState;
};

export const getNumberOfCheckedItems = (checkedState) => Array.from(checkedState.values()).filter((value) => value).length;
