import * as React from 'react';
import { Chip, Tooltip, Typography } from '@mui/material';
import { delegatedTagSx } from '../../../../constants/delegated_tag_sx';

const DelegationFieldsToolTip = ({
  delegationSyncFields,
  elementType,
  delegatedFromOrgName = null,
  additionalMessage = '',
  toolTipContent = null,
}) => {
  const renderSyncFields = () => {
    return `${delegationSyncFields
      .slice(0, -1)
      .map((field) => field.replaceAll('_', ' '))
      .join(', ')}, and ${delegationSyncFields[delegationSyncFields.length - 1].replaceAll('_', ' ')}`;
  };

  const isCreatedByDelegation = delegatedFromOrgName !== null;
  const mergedSx = { ...delegatedTagSx(isCreatedByDelegation), cursor: 'pointer', height: '24px' };
  const lowercaseElementType = elementType.toLowerCase();

  let tooltipTitle;
  if (isCreatedByDelegation) {
    tooltipTitle = `Changes to the ${renderSyncFields()} fields are managed by the parent organization and will be automatically updated here to reflect any modifications they make. These fields are not editable in your plan.`;
  } else {
    tooltipTitle = toolTipContent
      ? toolTipContent
      : `Changes to the ${renderSyncFields()}${additionalMessage} will be automatically copied to all plans to which you have delegated this ${lowercaseElementType}.`;
  }

  const chipLabel = isCreatedByDelegation ? `Delegated From: ${delegatedFromOrgName}...` : 'Delegated Field Updates...';

  return (
    <Tooltip arrow title={<Typography variant="body2">{tooltipTitle}</Typography>}>
      <Chip label={chipLabel} size="small" sx={mergedSx} />
    </Tooltip>
  );
};

export default DelegationFieldsToolTip;
