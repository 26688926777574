import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import SuccessIndicator from '../result/success_indicator';
import Status from '../result/status';
import Resources from '../shared/resources';

export default ({ measure }) => {
  const futureResults = measure.results_attributes.slice(0, measure.results_attributes_through_this_year.length);
  const startExpanded = futureResults.filter((result) => result.actual_results).length > 0;
  const [expanded, setExpanded] = React.useState(startExpanded);

  const showExpander = measure.results_attributes.length != measure.results_attributes_through_this_year.length;

  const maybeShowExpander = () => {
    const text = expanded
      ? 'show fewer dates'
      : `+ ${measure.results_attributes.length - measure.results_attributes_through_this_year.length} dates in future years`;
    const showText = showExpander ? text : '';
    return (
      <div className="d-flex flex-row-reverse expand-placeholder">
        <div
          onClick={() => setExpanded(!expanded)}
          style={{
            textAlign: 'right',
            height: showExpander ? 20 : 0,
            fontStyle: 'italic',
            cursor: 'pointer',
          }}
        >
          {showText}
        </div>
      </div>
    );
  };

  const mostRecentResults = (measure, index) => {
    return index == measure.results_attributes.findLastIndex((result) => result.actual_results != null);
  };

  const formatResults = (results, result_type) => {
    if (results !== null) {
      const formatted = parseFloat(parseFloat(results).toFixed(2)).toLocaleString();
      return result_type == 'percentage' ? `${formatted}%` : formatted;
    }
  };

  const renderDownloadLink = (attachments) => {
    return <Resources resources={attachments} hideDelete={true} handleUpdate={() => {}} />;
  };

  const displayedResults = expanded ? measure.results_attributes : measure.results_attributes_through_this_year;

  return (
    <>
      <TableCell align="left">
        <Table>
          <TableBody>
            {displayedResults
              .filter((result) => result.due_date !== undefined)
              .map((result, index) => {
                const [year, month, day] = result.due_date.split('-');
                const formattedDate = `${month}/${day}/${year}`;

                return (
                  <TableRow key={result.id}>
                    <TableCell style={{ padding: 0, minHeight: 24, borderRight: 0, borderBottom: 0 }} className="col-2">
                      <div className="d-flex w-100 justify-content-between" style={{ minHeight: 24 }}>
                        {mostRecentResults(measure, index) ? <strong>{formattedDate}</strong> : formattedDate}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell className="border-0 p-0">{maybeShowExpander(measure)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
      <TableCell align="left">
        <Table>
          <TableBody>
            {displayedResults.map((result, index) => (
              <TableRow key={result.id}>
                <TableCell style={{ padding: 0, minHeight: 24, borderRight: 0, borderBottom: 0 }} className="col-2">
                  <div className="d-flex w-100 align-items-center" style={{ minHeight: 24 }}>
                    <SuccessIndicator
                      actualResult={result.actual_results}
                      targetResult={measure.target_result_value}
                      isMostRecent={mostRecentResults(measure, index)}
                      direction={measure.direction}
                    />
                    {mostRecentResults(measure, index) ? (
                      <strong>{formatResults(result.actual_results, measure.target_result_type)}</strong>
                    ) : (
                      formatResults(result.actual_results, measure.target_result_type)
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell className="border-0 p-0">
                <div style={{ height: showExpander ? 20 : 0 }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
      <TableCell align="left" className="col-2">
        <Table>
          <TableBody>
            {displayedResults.map((result, index) => {
              return (
                <TableRow key={result.id}>
                  <TableCell className="border-0 p-0">
                    <div style={{ minHeight: 24, whiteSpace: 'nowrap' }}>
                      <Status {...result} mostRecent={mostRecentResults(measure, index)} />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell className="border-0 p-0">
                <div style={{ height: showExpander ? 20 : 0 }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
      <TableCell>
        {displayedResults.map((result) => (
          <div key={result.id}>{renderDownloadLink(result.attachment_attributes)}</div>
        ))}
        <div style={{ height: showExpander ? 20 : 0 }} />
      </TableCell>
    </>
  );
};
