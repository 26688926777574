import * as React from 'react';
import dueSoon from 'images/due_soon.png';
import dueSoon2x from 'images/due_soon@2x.png';
import overdue from 'images/overdueRed.png';
import overdue2x from 'images/overdueRed@2x.png';

export default (props) => {
  const indicatorIcon = () => {
    switch (props.status) {
      case 'due_soon': {
        return <img src={dueSoon} srcSet={`${dueSoon2x} 2x`} height="20px"></img>;
      }
      case 'overdue': {
        return <img src={overdue} srcSet={`${overdue2x} 2x`} height="20px"></img>;
      }
      case 'complete': {
        return null;
      }
      default: {
        return null;
      }
    }
  };

  const message = () => {
    switch (props.status) {
      case 'due_soon': {
        return 'Due w/in 30 Days';
      }
      case 'overdue': {
        return 'Overdue';
      }
      case 'complete': {
        return 'Complete';
      }
      case 'due': {
        return 'Due in 30+ Days';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="me-2">{props.mostRecent ? <strong>{message()}</strong> : message()}</div>
      <div>{indicatorIcon()}</div>
    </div>
  );
};
