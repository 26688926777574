import React, { forwardRef } from 'react';
import { Box, Chip } from '@mui/material';
import { delegatedTagSx } from '../../../constants/delegated_tag_sx';
import AnimatedDots from '../animated_dots';

const DelegatedTag = forwardRef(({ label = 'Delegated', sx = {}, isCreatedByDelegation = false, isInProgress = false }, ref) => {
  const mergedSx = { ...delegatedTagSx(isCreatedByDelegation), ...sx };
  return (
    <Chip
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {label}
          {isInProgress && <AnimatedDots color1={'#ccc'} color2={'#afafaf'} />}
        </Box>
      }
      title={label}
      size="small"
      sx={mergedSx}
      ref={ref}
    />
  );
});

export default DelegatedTag;
