import { trigger } from './helpers/events';

const registerGlobalEventListeners = () => {
  let expandAll = document.querySelector('.expand-all');
  let collapseAll = document.querySelector('.collapse-all');

  if (expandAll && collapseAll) {
    expandAll.addEventListener('click', () => {
      $('.accordion-button.collapsed').click();
      collapseAll.classList.remove('hide');
      expandAll.classList.add('hide');
    });

    collapseAll.addEventListener('click', () => {
      $('.accordion-button').not('.collapsed').click();
      expandAll.classList.remove('hide');
      collapseAll.classList.add('hide');
    });
  }

  // Deliverable Hide Completed
  let deliverableHideCompleted = document.querySelector('#hide_completed');
  if (deliverableHideCompleted) {
    deliverableHideCompleted.addEventListener('change', function (event) {
      history.pushState('', document.title, window.location.pathname + window.location.search);
      if (event.target.checked) {
        trigger('deliverable:hide_completed');
      } else {
        trigger('deliverable:show_completed');
      }
    });
  }

  // Deliverable Hide Delegated
  let deliverableHideDelegated = document.querySelector('#hide_delegated');
  if (deliverableHideDelegated) {
    deliverableHideDelegated.addEventListener('change', function (event) {
      if (event.target.checked) {
        trigger('deliverable:hide_delegated');
      } else {
        trigger('deliverable:show_delegated');
      }
    });
  }

  // Initiative Scheduling Detailed
  let initiativeShowDetailed = document.querySelector('#show_detailed');
  if (initiativeShowDetailed) {
    initiativeShowDetailed.addEventListener('click', function () {
      trigger('initiative_form:show_detailed');
    });
  }

  let initiativeHideDetailed = document.querySelector('#hide_detailed');
  if (initiativeHideDetailed) {
    initiativeHideDetailed.addEventListener('click', function () {
      trigger('initiative_form:hide_detailed');
    });
  }

  // Dashboard parent/child toggle
  let orgTables = document.querySelectorAll('.org-table');
  orgTables.forEach((orgTable) => {
    var dashboardParents = orgTable.querySelectorAll('.has-children');

    dashboardParents.forEach((p) => {
      p.addEventListener('click', () => {
        p.classList.toggle('open');
        var children = document.querySelectorAll(`[data-parent="${p.dataset.childrenOf}"]`);
        children.forEach((c) => c.classList.toggle('hide'));
      });
    });
  });
};

export default registerGlobalEventListeners;
