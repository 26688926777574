import React from 'react';
import { Box } from '@mui/material';

const AnimatedDots = ({ color1 = '#707070', color2 = '#A0A0A0' }) => {
  const dotPulse = {
    '@keyframes dotPulse': {
      '0%, 100%': {
        opacity: 1,
        backgroundColor: color1,
      },
      '50%': {
        opacity: 0.2,
        backgroundColor: color2,
      },
    },
  };

  return (
    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', marginLeft: '4px' }}>
      {Array.from({ length: 3 }).map((_, index) => (
        <Box
          key={index}
          component="span"
          sx={{
            ...dotPulse,
            width: '3px',
            height: '3px',
            borderRadius: '50%',
            animation: 'dotPulse 1.5s infinite',
            animationDelay: `${index * 0.3}s`,
            '&:not(:last-child)': {
              marginRight: '3px',
            },
          }}
        />
      ))}
    </Box>
  );
};

export default AnimatedDots;
