import * as React from 'react';
import greenDot from 'images/green_dot.png';
import yellowDot from 'images/yellow_dot.png';
import redDot from 'images/red_dot.png';

export default (props) => {
  if (props.actualResult == null) {
    return;
  } else if (props.direction == 'at_least') {
    if (props.actualResult >= props.targetResult) {
      return (
        <div className="d-flex justify-content-center" style={{ width: 15, marginRight: 5 }}>
          <img style={{ width: 15, height: 15 }} src={greenDot} height="20px"></img>
        </div>
      );
    } else if (props.actualResult >= props.targetResult * 0.9) {
      return (
        <div className="d-flex justify-content-center" style={{ width: 15, marginRight: 5 }}>
          <img style={{ width: 15, height: 15 }} src={yellowDot} height="20px"></img>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center" style={{ width: 15, marginRight: 5 }}>
          <img style={{ width: 15, height: 15 }} src={redDot} height="20px"></img>
        </div>
      );
    }
  } else if (props.direction == 'at_most') {
    if (props.actualResult <= props.targetResult) {
      return (
        <div className="d-flex justify-content-center" style={{ width: 15, marginRight: 5 }}>
          <img style={{ width: 15, height: 15 }} src={greenDot} height="20px"></img>
        </div>
      );
    } else if (props.actualResult <= props.targetResult * 1.1) {
      return (
        <div className="d-flex justify-content-center" style={{ width: 15, marginRight: 5 }}>
          <img style={{ width: 15, height: 15 }} src={yellowDot} height="20px"></img>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center" style={{ width: 15, marginRight: 5 }}>
          <img style={{ width: 15, height: 15 }} src={redDot} height="20px"></img>
        </div>
      );
    }
  }
};
