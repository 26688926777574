import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MeasureForm from './form';
import Modal from 'react-modal';
import MeasureResource from '../../resources/measure_resource';
import { useController } from 'rest-hooks';
import { useScrollToAnchor } from '../../helpers/hooks';
import { DefaultModalStyles } from '../../constants/react_modal_styles';
import { trackCzEvent } from '../../helpers/track_cz_event';
import Measure from './measure';

export default ({ id, modifiable, canDelegate, project_plan_id, ...props }) => {
  const [editedMeasure, setEditedMeasure] = useState(null);
  const { fetch } = useController();

  const close = (measure) => {
    setEditedMeasure(null);
    props.updateMeasure(measure);
  };

  useScrollToAnchor(() => {
    setEditedMeasure(props.measures.find((measure) => measure.id == location.hash.replace('#', '')));
  });

  const updateMeasure = (measure) => {
    return fetch(MeasureResource.update(), { id: editedMeasure.id }, { ...measure }).then((measureResponse) => {
      if (measureResponse.id) {
        setEditedMeasure(null);
      }
      props.updateMeasure(measureResponse);
      return measureResponse;
    });
  };

  const confirmDelete = (measure) => {
    if (confirm('Are you sure you want to delete this measure?')) {
      return fetch(MeasureResource.delete(), { id: measure.id }).then(() => {
        props.deleteMeasure(measure);
        trackCzEvent(`PlanDelete:Measure`, `User deleted Measure ${measure.id}`, 1);
      });
    }
  };

  Modal.setAppElement(document.getElementsByClassName('container-fluid')[0]);

  const deletable = modifiable || (props.measurableType === 'Initiative' && props.currentUserId === props.leadId);

  return (
    <>
      <Modal isOpen={editedMeasure != null} style={DefaultModalStyles} parentSelector={() => document.querySelector('body')}>
        <MeasureForm complete={close} measure={editedMeasure} onSubmit={updateMeasure} {...props} />
      </Modal>
      <div>
        <Table className="measures-table w-100" size="small" aria-label="simple table" style={{ margin: props.noMargin ? 0 : '' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 300 }} align="left">{`${props.measurableType} Measure`}</TableCell>
              <TableCell align="left">Target</TableCell>
              <TableCell align="left">Due Date(s)</TableCell>
              <TableCell align="left">Actual Results</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Resources</TableCell>
              {!props.hideActions && <TableCell style={{ width: 100 }} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.measures.map((measure) => (
              <Measure
                key={measure.id}
                measure={measure}
                projectPlanId={project_plan_id}
                modifiable={modifiable}
                deletable={deletable}
                canDelegate={canDelegate}
                hideDelegations={props.hideDelegations}
                hideActions={props.hideActions}
                measurableType={props.measurableType}
                confirmDelete={confirmDelete}
                setEditedMeasure={setEditedMeasure}
                currentUserId={props.currentUserId}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
