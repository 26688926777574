import React, { useEffect, useState } from 'react';
import { Button, Dialog, Stack, Typography, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { closeButtonSx } from '../../../../constants/dialog';
import { useDelegateDialog } from './delegate_dialog_context';
import ConfirmContent from './confirm_content';
import FormContent from './form_content';
import { buildTreeFromProjectPlans, getNumberOfCheckedItems, PLAN_ELEMENT_TYPES } from '../helpers';
import { upperFirst, camelCase, startCase } from 'lodash';
import { useCheckboxHandlers } from '../use_checkbox_handlers';
import { useContentStage } from '../use_content_stage';
import { trackCzEvent } from '../../../../helpers/track_cz_event';

const DelegateDialog = () => {
  const { open, originElement, targetPlans, closeDelegateDialog, saveData } = useDelegateDialog();
  const [planTree, setPlanTree] = useState([]);
  const [checkedState, setCheckedState] = useState(new Map());
  const [sendEmailConfirmation, setSendEmailConfirmation] = useState(true);

  const { handleCheckChange, handleSelectNone, handleSelectAll } = useCheckboxHandlers(setCheckedState);
  const { handleContentStageChange, isFormStage, isConfirmationStage } = useContentStage();

  useEffect(() => {
    if (open) {
      // Initialize treeData
      const hierarchicalData = buildTreeFromProjectPlans(targetPlans);
      setPlanTree(hierarchicalData);

      // Initialize checked states
      let initialState = new Map();
      targetPlans.forEach((plan) => {
        initialState.set(plan.org.id, plan.is_delegated_to_plan);
      });
      handleContentStageChange('form');
      setCheckedState(initialState);
    }
  }, [open, targetPlans]);

  const handleDelegate = () => {
    const newDelegationPlanIds = targetPlans.filter((plan) => checkedState.get(plan.org.id) && !plan.is_delegated_to_plan).map((plan) => plan.id);
    const unDelegationPlanIds = targetPlans.filter((plan) => !checkedState.get(plan.org.id) && plan.is_delegated_to_plan).map((plan) => plan.id);
    const delegationsParams = {
      delegations: {
        send_email_confirmation: sendEmailConfirmation,
        new_delegation_target_plan_ids: newDelegationPlanIds,
        un_delegation_target_plan_ids: unDelegationPlanIds,
        origin_elements: [{ id: originElement.id, type: PLAN_ELEMENT_TYPES[originElement.type] }],
      },
    };

    // Save data and pass callback function to track event
    saveData(delegationsParams, () => {
      trackCzEvent(
        `PlanDelegate:${upperFirst(camelCase(originElement.type))}`,
        `User delegated ${startCase(originElement.type)} ${originElement.id}`
      );
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => closeDelegateDialog()}
      disableEscapeKeyDown={true}
      maxWidth={'sm'}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{ paper: 'delegate-dialog' }}
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        Delegate {originElement.displayTitle}
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {originElement.description}
        </Typography>
      </DialogTitle>
      <IconButton aria-label="close" onClick={closeDelegateDialog} sx={closeButtonSx}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={true} sx={{ height: '35vh', display: 'flex', flexDirection: 'column' }}>
        <div id="scroll-dialog-description" tabIndex={-1} style={{ flexGrow: 1 }}>
          {isFormStage() && (
            <FormContent
              planTree={planTree}
              checkedState={checkedState}
              handleCheckChange={handleCheckChange}
              handleSelectAll={handleSelectAll}
              handleSelectNone={handleSelectNone}
            />
          )}
          {isConfirmationStage() && (
            <ConfirmContent elementTitle={originElement.displayTitle} numberOfCheckedItems={getNumberOfCheckedItems(checkedState)} />
          )}
        </div>
        {isConfirmationStage() && (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <FormControlLabel
              control={<Checkbox checked={sendEmailConfirmation} onChange={() => setSendEmailConfirmation((prevState) => !prevState)} />}
              label={'Send email confirmation'}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
          {isConfirmationStage() ? (
            <Button variant="contained" color="secondary" onClick={() => handleContentStageChange('form')}>
              Back
            </Button>
          ) : (
            <div></div>
          )}
          <Stack direction="row" spacing={2}>
            <Button onClick={() => closeDelegateDialog()}>Cancel</Button>
            {isFormStage() ? (
              <Button variant="contained" onClick={() => handleContentStageChange('confirmation')}>
                Confirm
              </Button>
            ) : (
              <Button variant="contained" onClick={handleDelegate}>
                Delegate
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default DelegateDialog;
