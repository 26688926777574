import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Stack, Typography } from '@mui/material';
import Link from '@mui/material/Link';

const FormContent = ({ planTree, checkedState, handleCheckChange, handleSelectAll, handleSelectNone }) => {
  const renderCheckbox = (node) => {
    return (
      <FormControlLabel
        control={<Checkbox checked={checkedState.get(node.org.id) || false} onChange={(e) => handleCheckChange(node.org.id, e.target.checked)} />}
        label={node.org.name}
      />
    );
  };

  const renderPlans = (nodes, depth = 0) => {
    return nodes.map((node) => (
      <Grid item key={node.id} xs={12} sx={{ marginLeft: depth * 2, paddingTop: '0 !important' }}>
        {renderCheckbox(node)}
        {node.children && renderPlans(node.children, depth + 1)}
      </Grid>
    ));
  };

  return (
    <div>
      <Stack direction="row" spacing={1} alignItems="center" marginTop={1}>
        <Typography variant="body1">Select:</Typography>
        <Link href="#" onClick={handleSelectAll} variant="body1" className="link-default">
          All
        </Link>
        <Typography variant="body2">/</Typography>
        <Link href="#" onClick={handleSelectNone} variant="body1" className="link-default">
          None
        </Link>
      </Stack>
      <Grid container spacing={2} marginTop={2}>
        {renderPlans(planTree)}
      </Grid>
    </div>
  );
};

FormContent.propTypes = {
  planTree: PropTypes.array.isRequired,
  checkedState: PropTypes.object.isRequired,
  handleCheckChange: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleSelectNone: PropTypes.func.isRequired,
};

export default FormContent;
