import React, { useState, useEffect } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import ResultForm from '../result/form';
import ResultResource from '../../resources/result_resource';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { confirmChangesBeforeCanceling } from '../../helpers/confirm_cancel';
import { useBeforeunload } from 'react-beforeunload';
import { useController } from 'rest-hooks';
import { textError } from '../../helpers/rails_errors';
import DelegationFieldsToolTip from '../shared/delegations/plan_element/delegation_fields_tooltip';
import { trackCzEvent } from '../../helpers/track_cz_event';
import { showDelegationFieldsToolTip, isFieldSynced } from '../../helpers/delegation_helper';

export default ({ ...props }) => {
  const { fetch } = useController();

  const [measureState, setMeasureState] = useState({
    id: (props.measure && props.measure.id) || undefined,
    measurable_id: (props.measure && props.measure.measurable_id) || undefined,
    name: (props.measure && props.measure.name) || undefined,
    direction: (props.measure && props.measure.direction) || undefined,
    target_result_value: (props.measure && props.measure.target_result_value) || undefined,
    target_result_type: (props.measure && props.measure.target_result_type) || undefined,
    description: (props.measure && props.measure.description) || undefined,
    results_attributes:
      props.measure && props.measure.results_attributes ? props.measure.results_attributes.map((r, idx) => ({ ...r, index: idx })) : [],
    results_reporter_id: (props.measure && props.measure.results_reporter_id) || props.defaultResultsReporterId || undefined,
    lock_version: props.measure && props.measure.lock_version,
  });

  const [errorState, setErrorState] = useState({
    name: [],
    direction: [],
    target_result_value: [],
    target_result_type: [],
    results_attributes: [],
    results_reporter: [],
    base: [],
  });

  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setMeasureState({ ...measureState, results_attributes: sortByDueDates(measureState.results_attributes) });
  }, []);

  useBeforeunload((event) => {
    if (edited) {
      event.preventDefault();
    }
  });

  const updateMeasureProperty = (key, value) => {
    if (key != 'results_attributes') setEdited(true);
    setMeasureState({
      ...measureState,
      [key]: value,
    });
    setErrorState({
      ...errorState,
      [key]: [],
    });
  };

  const addResult = () => {
    updateMeasureProperty(
      'results_attributes',
      // since this result has not been persisted, it needs a temp_id that we
      // can use to identify it and differentiate it from other unpersisted results
      measureState.results_attributes.concat([
        {
          temp_id: Math.random(),
          due_date: undefined,
          actual_results: undefined,
          notes: undefined,
          attachment_attributes: [],
          index: measureState.results_attributes.length,
        },
      ])
    );
  };

  useEffect(() => {
    if (measureState.results_attributes.length == 0) addResult();
  }, []);

  const updateResult = (updatedResult) => {
    setEdited(true);
    const updated = [...measureState.results_attributes];
    const replaceIndex = measureState.results_attributes.findIndex((result) => {
      if ('temp_id' in result) {
        return result.temp_id == updatedResult.temp_id;
      } else {
        return result.id == updatedResult.id;
      }
    });
    updated[replaceIndex] = updatedResult;
    updateMeasureProperty('results_attributes', updated);
  };

  const updateResultAttachments = (measureWithUpdatedResults) => {
    if (props.updateMeasure) {
      props.updateMeasure(measureWithUpdatedResults);
    }
  };

  const deleteResult = (result) => {
    const processDeleted = (result) => {
      const updated = [...measureState.results_attributes];
      const replaceIndex = measureState.results_attributes.findIndex((r) => {
        if ('temp_id' in result) {
          return result.temp_id == r.temp_id;
        } else {
          return result.id == r.id;
        }
      });
      updated.splice(replaceIndex, 1);
      updateMeasureProperty('results_attributes', updated);
    };

    if (!('temp_id' in result)) {
      fetch(ResultResource.delete(), { id: result.id })
        .then(() => {
          processDeleted(result);
          trackCzEvent(`PlanDelete:Result`, `User deleted Result ${result.id}`, 1);
        })
        .catch((error) => {
          setErrorState(error.response?.data?.errors || { base: 'Unknown error' });
        });
    } else {
      processDeleted(result);
    }
  };

  const onSubmit = () => {
    props
      .onSubmit(measureState)
      .then((res) => {
        props.complete(res);
        setEdited(false);
        if (props.measure) {
          trackCzEvent(`PlanEdit:Measure`, `User edited Measure ${props.measure.id}`, 1);
        } else {
          trackCzEvent(`PlanAdd:Mesaure`, `User added 1 Measure`, 1);
        }
      })
      .catch((error) => {
        setErrorState(error.response?.data?.errors || { base: 'Unknown error' });
      });
  };

  const resultErrorState = (index) => {
    const idx = Object.values(errorState).findIndex((arr) => arr[0] == index);
    if (!Object.keys(errorState)[idx]) return {};
    const errorNum = Object.keys(errorState)[idx].split('[').pop().split(']')[0];
    return {
      due_date: errorState[`results[${errorNum}].due_date`],
      actual_results: errorState[`results[${errorNum}].actual_results`],
    };
  };

  const sortByDueDates = (results) => {
    return results.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
  };

  const close = () => {
    if (!props.measure) {
      props.complete();
      return;
    }
    const resultsWithUpdatedAttachments = props.measure.results_attributes.map((result, index) => {
      return {
        ...result,
        attachment_attributes: measureState.results_attributes[index].attachment_attributes,
      };
    });
    props.complete({ ...props.measure, results_attributes: resultsWithUpdatedAttachments });
  };

  const toolTipContent =
    'Changes to the name, description, result type, target, and direction (at least / at most), will be copied to all plans to which you have delegated this measure. In addition, each result row will be copied to those plans, with the exception of the actual results field which should be added in the child plans when the reporting dates arrive.';

  return (
    <div className="measures-form">
      <div className="mb-4">
        <div className="d-flex justify-content-between">
          <h3 className="mb-0">{`${props.measure ? 'Edit' : 'New'} ${props.measurableType} Measure`}</h3>
          <div role="button" onClick={() => confirmChangesBeforeCanceling(edited, close)}>
            &#x2715;
          </div>
        </div>
        {props.measure && showDelegationFieldsToolTip(props.measure) && (
          <div className="w-100">
            <DelegationFieldsToolTip
              isDelegated={props.measure['delegated?']}
              delegationSyncFields={props.measure['delegation_sync_fields']}
              delegatedFromOrgName={props.measure['delegated_from_org_name']}
              elementType={'Measure'}
              additionalMessage=", as well as any results"
              toolTipContent={toolTipContent}
            />
          </div>
        )}
      </div>
      <FormControl
        error={errorState.target_result_type && errorState.target_result_type.length > 0}
        disabled={isFieldSynced('target_result_type', props.measure)}
      >
        <FormLabel id="measure-target-type">Measure Type</FormLabel>
        <RadioGroup
          row
          aria-labelledby="measure-target-type"
          id="measure-target-result-type"
          name="row-radio-buttons-group"
          onChange={(e) => updateMeasureProperty('target_result_type', e.target.value)}
          defaultValue={measureState.target_result_type}
        >
          <FormControlLabel value="total_count" control={<Radio />} label="Count" />
          <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
        </RadioGroup>
        <FormHelperText>{errorState.target_result_type && errorState.target_result_type.join(', ')}</FormHelperText>
      </FormControl>
      <Grid container spacing={1} className="mt-3">
        <Grid item xs style={{ flexGrow: 1 }}>
          <TextField
            error={errorState.name && errorState.name.length > 0}
            helperText={errorState.name && errorState.name.join(', ')}
            fullWidth
            id="measure-name"
            label="Measure Name"
            variant="outlined"
            value={measureState.name || ''}
            onChange={(e) => updateMeasureProperty('name', e.target.value)}
            disabled={isFieldSynced('name', props.measure)}
          />
        </Grid>
        <Grid item>
          <FormControl sx={{ width: 120 }} variant="outlined" error={errorState.direction && errorState.direction.length > 0}>
            <Select
              value={measureState.direction || props.direction || 'at_least'}
              onChange={(e) => updateMeasureProperty('direction', e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
              id="measure-direction"
              labelId="measure-direction-label"
              className="unset-legend-width"
              disabled={isFieldSynced('direction', props.measure)}
            >
              <MenuItem key="at_least" value="at_least">
                At Least
              </MenuItem>
              <MenuItem key="at_most" value="at_most">
                At Most
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            sx={{ width: 80 }}
            error={errorState.target_result_value && errorState.target_result_value.length > 0}
            id="measure-target-result-value"
            label="Target"
            variant="outlined"
            value={measureState.target_result_value || ''}
            onChange={(e) => updateMeasureProperty('target_result_value', e.target.value)}
            disabled={isFieldSynced('target_result_value', props.measure)}
          />
        </Grid>
      </Grid>
      {errorState.target_result_value && errorState.target_result_value.length > 0 && (
        <Typography
          style={{
            color: '#d32f2f',
            fontSize: '0.75em',
            textAlign: 'right',
            marginTop: errorState.name && errorState.name.length > 0 ? '-20px' : '0px',
          }}
        >
          {errorState.target_result_value && errorState.target_result_value.join(' ')}
        </Typography>
      )}

      <TextField
        multiline
        label="Description"
        className="me-3 mt-3 w-100"
        id="measure-target-result-value"
        variant="outlined"
        value={measureState.description || ''}
        onChange={(e) => updateMeasureProperty('description', e.target.value)}
        disabled={isFieldSynced('description', props.measure)}
      />

      <FormControl fullWidth className="mt-3">
        <InputLabel id="measure-results-reporter-label">Results Reporter</InputLabel>
        <Select
          error={errorState.results_reporter && errorState.results_reporter.length > 0}
          labelId="measure-results-reporter-label"
          id="measure-results-reporter"
          value={measureState.results_reporter_id || props.defaultResultsReporterId || ''}
          label="Results Reporter"
          onChange={(e) => updateMeasureProperty('results_reporter_id', e.target.value)}
        >
          {props.potentialResultsReporters.map((reporter) => (
            <MenuItem key={reporter.id} value={reporter.id}>
              {`${reporter.first_name} ${reporter.last_name || ''}`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{errorState.results_reporter && errorState.results_reporter.join(', ')}</FormHelperText>
      </FormControl>

      <Table className="mt-3">
        <TableHead>
          <TableRow>
            <TableCell className="col-2">Data Reporting Due Date</TableCell>
            <TableCell className="col-2">Actual Results</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell className="col-1">Resources</TableCell>
            <TableCell className="col-1">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {measureState.results_attributes.map((result, index) => (
            <ResultForm
              key={result.id}
              result={result}
              updateResult={updateResult}
              updateResultAttachments={updateResultAttachments}
              deleteResult={deleteResult}
              errors={resultErrorState(index)}
              index={index}
            />
          ))}
        </TableBody>
      </Table>
      <div className="p-3">{!!errorState?.base && <FormHelperText error>{textError(errorState.base)}</FormHelperText>}</div>
      <button className="btn btn-att-gray-medium px-3 m-2" onClick={addResult}>
        Add a Date &#xFF0B;
      </button>
      <button className="btn btn-att-blue-medium" onClick={onSubmit} data-cy="m-save">
        Save
      </button>
    </div>
  );
};
