import RailsResource from './rails_resource';

export default class MeasureResource extends RailsResource {
  id = undefined;
  name = null;
  target_result_value = null;
  target_result_type = null;
  results_attributes = [];
  results_attributes_through_this_year = [];
  results = [];
  results_reporter = null;
  default_results_reporter_id = null;
  lock_version = null;

  pk() {
    return this.id;
  }

  static urlRoot = '/measures/';
}
