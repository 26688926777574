export const confirmChangesBeforeCanceling = (hasUnsavedChanges, callback) => {
  if (hasUnsavedChanges) {
    const confirmCancel = confirm('You have unsaved changes. Are you sure you want to cancel?');
    if (confirmCancel) {
      callback();
    }
  } else {
    callback();
  }
};
