import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DelegatedTag from '../shared/delegations/delegated_tag';
import ActionMenuButton from '../shared/action_menu_button';
import ResultsTable from '../result/table';
import EditAction from '../actions/edit';
import { transparentButtonSX } from '../../constants/theme';
import { faSitemap, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { useDelegateDialog } from '../shared/delegations/plan_element/delegate_dialog_context';
import { getJobLog } from '../../apis/job_logs/api';
import { getMeasure } from '../../apis/measures/api';

export default ({ measure, projectPlanId, ...props }) => {
  const { modifiable, deletable, canDelegate, hideDelegations, hideActions, measurableType, confirmDelete, setEditedMeasure, currentUserId } = props;
  const { openDelegateDialog, jobLog, clearJobLog } = useDelegateDialog();
  const [pendingDelegationJob, setPendingDelegationJob] = useState(null);
  const [delegationProps, setDelegationProps] = useState({
    'delegated?': measure['delegated?'],
    delegation_sync_fields: measure['delegation_sync_fields'],
    delegated_from_org_name: measure['delegated_from_org_name'],
  });

  const delegatedFromName = () => (!hideDelegations ? measure['delegated_from_org_name'] : null);
  const isDelegationInProgress = () => pendingDelegationJob && !delegationProps['delegated?'];
  const isDelegated = () => delegationProps['delegated?'] && !hideDelegations;

  const menuItems = [];
  if (canDelegate) {
    const displayTitle = `${measurableType} Measure`;
    menuItems.push({
      label: 'Delegate',
      icon: faSitemap,
      onClick: () => {
        openDelegateDialog(projectPlanId, {
          id: measure.id,
          type: 'measure',
          description: measure.name,
          displayTitle: displayTitle,
        });
      },
    });
  }
  if (deletable && !delegatedFromName()) {
    menuItems.push({
      label: 'Delete',
      icon: faTrashCan,
      onClick: () => confirmDelete(measure),
      disabled: false,
    });
  }

  useEffect(() => {
    const jobLogId = jobLog?.id;
    if (!pendingDelegationJob && jobLog?.originElement?.id === measure.id && jobLogId) {
      setPendingDelegationJob(jobLog);
      clearJobLog();
    }
  }, [jobLog]);

  useEffect(() => {
    if (pendingDelegationJob) {
      let log;
      const intervalId = setInterval(async () => {
        log = await getJobLog(pendingDelegationJob.id);
        if (log.status !== 'pending') {
          clearInterval(intervalId);
          setPendingDelegationJob(null);
          if (log.status === 'success') {
            const newMeasure = await getMeasure(measure.id);
            setDelegationProps({
              'delegated?': newMeasure['delegated?'],
              delegation_sync_fields: newMeasure['delegation_sync_fields'],
              delegated_from_org_name: newMeasure['delegated_from_org_name'],
            });
          } else if (log.status === 'failure') {
            alert('Delegation failed. Please reload the page and try again.');
          }
        }
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [pendingDelegationJob]);

  const handleEdit = () => {
    const updatedMeasure = {
      ...measure,
      'delegated?': delegationProps['delegated?'],
      delegation_sync_fields: delegationProps.delegation_sync_fields,
      delegated_from_org_name: delegationProps.delegated_from_org_name,
    };
    setEditedMeasure(updatedMeasure);
  };

  return (
    <TableRow id={measure.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {isDelegationInProgress() && (
          <div>
            <DelegatedTag
              label="Delegating"
              isInProgress={true}
              sx={{
                color: '#707070',
                border: '1px solid #ccc',
              }}
            />
          </div>
        )}
        {isDelegated() && (
          <div>
            <DelegatedTag />
          </div>
        )}
        {delegatedFromName() && (
          <div>
            <DelegatedTag label={`Delegated from: ${delegatedFromName()}`} isCreatedByDelegation={true} sx={{ textTransform: 'capitalize' }} />
          </div>
        )}
        {measure.name}
      </TableCell>
      <TableCell align="left">
        {measure.direction === 'at_least' ? '>' : '<'}{' '}
        {`${measure.target_result_value.toLocaleString()}${measure.target_result_type === 'percentage' ? '%' : ''}`}
      </TableCell>
      <ResultsTable measure={measure} />
      {!hideActions && (
        <TableCell>
          <div className="d-flex align-items-center">
            {modifiable || measure.results_reporter_id === currentUserId ? <EditAction handleClick={handleEdit} /> : null}
            {!hideDelegations && menuItems.length > 0 && <ActionMenuButton menuItems={menuItems} sx={transparentButtonSX} />}
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};
