import { getHeaders } from '../headers';

export const getMeasure = async (id) => {
  const response = await fetch(`/measures/${id}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
