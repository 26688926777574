import { useCallback } from 'react';
import { selectNone, selectAll, updateSingleCheck } from './helpers';

export const useCheckboxHandlers = (setCheckedState) => {
  const handleCheckChange = useCallback(
    (id, isChecked) => setCheckedState((prevState) => updateSingleCheck(prevState, id, isChecked)),
    [setCheckedState]
  );

  const handleSelectNone = useCallback(() => setCheckedState((prevState) => selectNone(prevState)), [setCheckedState]);

  const handleSelectAll = useCallback(() => setCheckedState((prevState) => selectAll(prevState)), [setCheckedState]);

  return { handleCheckChange, handleSelectNone, handleSelectAll };
};
