import { useState, useCallback } from 'react';

export const useContentStage = (initialStage = 'form') => {
  const [contentStage, setContentStage] = useState(initialStage);

  const handleContentStageChange = useCallback((newStage) => {
    setContentStage(newStage);
  }, []);

  const isFormStage = useCallback(() => contentStage === 'form', [contentStage]);
  const isConfirmationStage = useCallback(() => contentStage === 'confirmation', [contentStage]);

  return {
    contentStage,
    handleContentStageChange,
    isFormStage,
    isConfirmationStage,
  };
};
