import { getHeaders } from '../../../../apis/headers';

export const getTargetPlans = async (planId, elementId, elementType) => {
  const response = await fetch(`/project_plans/${planId}/delegations/target_plans?element_id=${elementId}&element_type=${elementType}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const saveDelegations = async (planId, delegationsParams) => {
  const response = await fetch(`/project_plans/${planId}/delegations`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(delegationsParams),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const getDelegatedPlanElements = async (planId) => {
  const response = await fetch(`/project_plans/${planId}/delegations/delegated_plan_elements`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
