export const delegatedTagSx = (isCreatedByDelegation = false) => {
  const defaults = {
    height: '20px',
    fontSize: '11px',
    fontWeight: 500,
    textTransform: 'uppercase',
    borderRadius: 16,
    marginTop: '2px',
    backgroundColor: 'transparent',
    '& .MuiChip-label': {
      paddingLeft: '8px',
      paddingRight: '8px',
      maxWidth: '270px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };

  if (isCreatedByDelegation) {
    return {
      ...defaults,
      color: '#A36482',
      border: '1px solid #A36482',
    };
  } else {
    return {
      ...defaults,
      color: '#724D91',
      border: '1px solid #724D91',
    };
  }
};
