import * as React from 'react';
import deleteIcon from 'images/trash_icon.png';

export default (props) => {
  const style = {
    cursor: 'pointer',
    padding: '0 5px',
  };

  return (
    <a style={style} onClick={props.handleClick} className="delete-btn no-print" disabled={props.disabled} data-cy="delete">
      <img src={deleteIcon} height="20px"></img>
    </a>
  );
};
