import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { Zoom, Box, IconButton, useScrollTrigger } from '@mui/material';

const style = {
  position: 'fixed !important',
  left: 20,
  bottom: 100,
  zIndex: 5000,
};

const BackToTop = () => {
  const trigger = useScrollTrigger({
    threshold: 700,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Zoom in={trigger}>
      <Box role="presentation" sx={style}>
        <IconButton
          aria-label="scroll back to top"
          title="Scroll back to top"
          component="span"
          onClick={scrollToTop}
          sx={{
            borderRadius: '50%',
            fontSize: '1.2em',
            padding: '7px 9px',
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: (theme) => theme.palette.grey[500],
            color: (theme) => theme.palette.grey[500],
            transition: 'transform 0.3s ease',
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: (theme) => theme.palette.grey[600],
              color: (theme) => theme.palette.grey[600],
              transform: 'translateY(-4px)',
            },
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </IconButton>
      </Box>
    </Zoom>
  );
};

export default BackToTop;
